<div class="intro-box">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <div class="intro-content">
        AlphaFold DB provides open access to over 200 million protein structure predictions to accelerate scientific research.
      </div>
    </div>
  </div>
</div>

<section>
  <div class="row info-section first-section">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>Background</h3>
      <div class="row">

        <div class="columns medium-6 info-content">
          <p class="large-text">
            <a (click)="gaService.eventEmitter(
            'link_visit',
            'home_href_click',
            'click',
            'dm_blog',
            '')" href="https://deepmind.com/blog/article/putting-the-power-of-alphafold-into-the-worlds-hands"
               target="_blank">AlphaFold</a>
            is an AI system developed by <a (click)="gaService.eventEmitter(
            'link_visit',
            'home_href_click',
            'click',
            'deepmind',
            '')" href="https://deepmind.google/" target="_blank">Google DeepMind</a>
            that predicts a protein’s 3D structure from its amino acid
            sequence. It regularly achieves accuracy competitive with experiment.
          </p>

          <p class="normal-text">
            Google DeepMind and EMBL’s European Bioinformatics Institute
            (<a href="http://www.ebi.ac.uk" target="_blank">EMBL-EBI</a>) have partnered to create AlphaFold DB
            to make these predictions freely available to the scientific community. The latest database release contains over 200 million entries,
            providing broad coverage of <a href="https://www.uniprot.org/uniprotkb" target="_blank">UniProt</a> (the standard repository of protein sequences and annotations). We provide individual
            <a [routerLink]="['/download']">downloads</a> for the human proteome and for the proteomes of 47 other key organisms important in research and global health.
            We also provide a download for the manually curated subset of UniProt (<a href="https://www.expasy.org/resources/uniprotkb-swiss-prot" target="_blank">Swiss-Prot</a>).
          </p>
          <p>
        </div>

        <div class="columns medium-6 info-content" style="text-align: center;">
          <img class="ex-img" src="assets/img/Q8I3H7_1.png">
          <p class="img-caption">Q8I3H7: May protect the malaria parasite against attack by the immune system. Mean
            pLDDT 85.57.</p>
          <p class="img-caption"><a [routerLink]="['entry','Q8I3H7']" class="img-caption">View protein</a></p>
        </div>

      </div>

      <div class="row info-section-row-2">

        <div class="columns medium-6 medium-push-6 info-content">
          <p class="large-text" style="margin-bottom:30px;">
            In <a (click)="gaService.eventEmitter('link_visit', 'home_href_click', 'click','casp14', '')"
                  href="https://predictioncenter.org/casp14/zscores_final.cgi" target="_blank">CASP14</a>,
            AlphaFold was the top-ranked protein structure prediction method by a large margin, producing
            predictions with <a
            (click)="gaService.eventEmitter('link_visit', 'home_href_click', 'click', 'faq_accuracy', '')" [routerLink]="['faq']" fragment="faq-3">high accuracy</a>. While the system still has some
            <a (click)="gaService.eventEmitter('link_visit', 'home_href_click', 'click', 'faq_limitations', '')" [routerLink]="['faq']" fragment="faq-6">limitations</a>,
            the CASP results suggest AlphaFold
            has immediate potential to help us understand the structure of proteins and advance biological research.</p>
          <p class="normal-text">Let us know how the AlphaFold Protein Structure Database has been useful in your research, or if
            you have questions not answered in the <a [routerLink]="['faq']">FAQs,</a> at
            <a class="licenseLink"
               href="mailto:alphafold@deepmind.com">alphafold@deepmind.com</a>.</p>
          <p class="normal-text">
            If your use case isn’t covered by the database, you can generate your own AlphaFold
            predictions using Google DeepMind’s <a href="https://bit.ly/alphafoldcolab" (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'colab_code',
      '')" target="_blank">Colab notebook</a> or <a (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'source_code',
      '')" href="https://github.com/deepmind/alphafold/" target="_blank">open source code</a>.
            Both resources also support multimer prediction.
          </p>
        </div>

        <div class="columns medium-6 medium-pull-6 info-content" style="text-align: center;">
          <img class="ex-img" src="assets/img/Q8W3K0.png">
          <p class="img-caption">Q8W3K0: A potential plant disease resistance protein. Mean pLDDT 82.24.</p>
          <p class="img-caption"><a [routerLink]="['entry','Q8W3K0']" class="img-caption">View protein</a></p>
        </div>

      </div>

    </div>
  </div>
</section>


<div class="find-more-box">
  <div class="row">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>Find out more</h3>
      <div class="row">
        <app-home-links [findOutMore]="findOutMore"></app-home-links>
        <div class="columns medium-6">
        </div>
      </div>
    </div>
  </div>
</div>

<section>
  <div id="news" class="row info-section">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>What’s new?</h3>
      <div class="row">

        <div class="columns medium-6 medium-push-6 info-content">
          <p class="large-text" style="margin-bottom:30px;">
            Integration of AlphaMissense data – May 2024</p>
          <p class="normal-text">
            You can now explore the predicted structures for canonical human proteins and seamlessly toggle between two key visual representations, the quality of structure model (pLDDT) and the average pathogenicity scores from <a target="_blank" [routerLink]="['/faq']" fragment="faq-22">AlphaMissense</a>. This enables the examination of missense variations at the residue level in the context of protein 3D structures. The variant data is displayed using an innovative and interactive heatmap and 3D visualisation, offering insights into the broader implications of specific residue changes. The AFDB update aids researchers in identifying structural areas to guide their investigations into protein function and provides easy access to download the data.
          </p>
          <p><a href="https://www.ebi.ac.uk/about/news/technology-and-innovation/alphamissense-data-integration/" target="_blank" (click)="gaService.eventEmitter(
            'social_link_visit',
            'Whats_new',
            'click',
            'Item_EBI_link',
            'Click on link to social media news item  ')">Go to news item</a></p>
        </div>

        <div class="columns medium-6 medium-pull-6 info-content" style="text-align: center;">
          <img class="news-img" src="assets/img/test.alphafold.ebi.ac 4.png" width="535px" height="484px">
          <p class="img-caption">P04637: Cellular tumor antigen p53</p>
          <p class="img-caption"><a (click)="gaService.eventEmitter(
            'example_link_visit',
            'Whats_new',
            'click',
            'AM_protein_example',
            'Click on link to see the AM p53 example [View protein]')" [routerLink]="['entry','P04637']" target="_blank" class="img-caption">View protein</a></p>
        </div>

      </div>
    </div>
  </div>
</section>

<section>
  <div class="row info-section">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>What’s next?</h3>
      <p class="large-text" style="margin-bottom: 0;">
        We plan to continue updating the database with structures for newly discovered protein sequences, and to improve features and functionality in response to user feedback.
        Please follow <a (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'deepmind_social',
      '')" href="https://twitter.com/GoogleDeepMind" target="_blank">Google DeepMind</a>'s and
        <a (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'embl_social',
      '')" href="https://twitter.com/emblebi" target="_blank">EMBL-EBI</a>’s social channels for updates.
      </p>
    </div>
  </div>
</section>

<section>
  <div class="row info-section more-spacing">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>Licence and attribution</h3>
      <p class="large-text" style="margin-bottom: 0;">
        All of the data provided is freely available for both academic and commercial use under Creative Commons
        Attribution 4.0 (<a (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'license',
      '')" href="https://creativecommons.org/licenses/by/4.0/" target="_blank">CC-BY 4.0</a>) licence terms.
        
      </p>
      <p class="small-text" style="margin-bottom: 0; margin-top: 20px;">
        If you use this resource, please cite the following papers:<br>
        <a href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">
          Jumper, J <i>et al</i>. Highly accurate protein structure prediction with AlphaFold. <i>Nature</i> (2021).
        </a><br>
        <a href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkad1011/7337620" target="_blank">
          Varadi, M <i>et al</i>. AlphaFold Protein Structure Database in 2024: providing structure coverage for over 214 million protein sequences. <i>Nucleic Acids Research</i> (2024).
        </a>
      </p>
      <p class="small-text" style="margin-bottom: 0; margin-top: 20px;">
        If you use data from AlphaMissense in your work, please cite the following paper:<br>
        <a href="https://www.science.org/doi/10.1126/science.adg7492" target="_blank">Cheng, J et al. Accurate proteome-wide missense variant effect prediction with AlphaMissense. Science (2023).
        </a><br>
      </p>
      <p class="small-text" style="margin-bottom: 0; margin-top: 20px;">
        
      </p>
      <p class="small-text" style="margin-bottom: 0;">

        The structures and data provided in this resource are predictions with <a (click)="gaService.eventEmitter(
          'link_visit',
          'home_href_click',
          'click',
          'faq_accuracy',
          '')" [routerLink]="['/faq']" fragment="faq-12">varying levels of confidence</a> and should be interpreted carefully. The information is for theoretical modelling only. It is not intended, validated or approved for any clinical use.
      </p>
    </div>
  </div>
</section>

<section>
  <div class="row info-section">
    <div class="columns small-12 medium-10 medium-offset-1">
      <h3>FAQs</h3>
      <app-faqs-list [count]="2" [idsFilter]="['faq-9', 'faq-14', 'faq-19']"></app-faqs-list>
      <p class="faq-link">
        <a [routerLink]="['faq']">View all frequently asked questions</a>
      </p>
    </div>
  </div>
</section>
