import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  apiUrls = {};

  constructor(private http: HttpClient) {
  }

  /**
   * Make single API request
   */
  getApiData(apiUrl: string, metaData?: any): Observable<any> {
    // Get the token & create headers
    const headerProps = {};
    if (metaData && metaData.isInternal) { headerProps['X-Source'] = 'alphafold';
    headerProps['X-Source'] = 'alphafold'; }
    const headers = new HttpHeaders(headerProps);
    return this.http.get(apiUrl, { headers })
      .pipe(
        tap(
          data => {
            if(metaData) data['metaData'] = metaData;
            return data;
          }
        ),
        catchError(this.handleError(apiUrl))
      );
  }


  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param apiUrl - name of the operation that failed
   */
  private handleError(apiUrl = 'API request'): any {
    return (error: any): Observable<any> => {
      const err = [
        apiUrl,
        error.status
      ];
      return of(err);
    };
  }

  // Comparator to retain object key/value order for template pipe
  keepDefaultOrder(a: any, b: any) {
    return a;
  }

  escapeValue(value: string): string {
    let cleanVal = value;
    // else if it is a text search, don't put quotes around the search term when there is a space
    // if (value.match(/[:\/"]/) && !value.match(/[\[\{]\S+ TO \S+[\]\}]/) && !value.match(/^["\(].*["\)]$/)) {
    //   cleanVal = '"' + value.replace(/\\/g, '\\\\').replace(/"/g, '\\"') + '"';
    // }
    return this.fixedEncodeURIComponent(cleanVal);
  }

  fixedEncodeURIComponent(str: string) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  decodeURIValue(value: string): string {
    return decodeURIComponent(value);
  }
}
