import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AppHeaderComponent} from './app-header/app-header.component';
import {SummaryTextComponent} from './summary-text/summary-text.component';
import {EntryComponent} from './entry/entry.component';
import {HomeComponent} from './home/home.component';
import {RouterModule, Routes} from '@angular/router';
import {CommonService} from './common.service';
import {HttpClientModule} from '@angular/common/http';
import {AboutComponent} from './about/about.component';
import {GoogleAnalyticsService} from './google-analytics.service';
import {ConfigService} from './config.service';
import {DownloadComponent} from './download/download.component';
import {SearchComponent} from './search/search.component';
import {SearchPaginationComponent} from './search-pagination/search-pagination.component';
import {SearchResultCardComponent} from './search-result-card/search-result-card.component';
import {FaqComponent} from './faq/faq.component';
import {HeaderSearchComponent} from './header-search/header-search.component';
import {FaqsListComponent} from './faqs-list/faqs-list.component';
import {PaeViewComponent} from './pae-view/pae-view.component';
import {EntryStatusComponent} from './entry-status/entry-status.component';
import {SwaggerComponent} from "./swagger/swagger.component";
import { HomeLinksComponent } from './home-links/home-links.component';
import { SequenceCardComponent } from './sequence-card/sequence-card.component';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import {MatTabsModule} from '@angular/material/tabs';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ClusterTableComponent } from './cluster-table/cluster-table.component'
import { MatSortModule } from '@angular/material/sort';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { HeatmapComponent } from './heatmap/heatmap.component';
import { MatSliderModule } from '@angular/material/slider';
import { FileDownloadComponent } from './file-download/file-download.component';
import { NewsPopupComponent } from './news-popup/news-popup.component';

const routes: Routes = [];

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    SummaryTextComponent,
    EntryComponent,
    HomeComponent,
    AboutComponent,
    DownloadComponent,
    SearchComponent,
    SearchPaginationComponent,
    SearchResultCardComponent,
    FaqComponent,
    HeaderSearchComponent,
    FaqsListComponent,
    SwaggerComponent,
    PaeViewComponent,
    EntryStatusComponent,
    HomeLinksComponent,
    SequenceCardComponent,
    ClusterTableComponent,
    HeatmapComponent,
    FileDownloadComponent,
    NewsPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    OverlayModule,
    MatCheckboxModule,
    MatSortModule,
    MatButtonToggleModule,
    MatSliderModule,
    RouterModule.forRoot(routes, { scrollOffset: [0, 0], scrollPositionRestoration: "top", anchorScrolling: 'enabled' }),
  ],
  providers: [
    CommonService,
    GoogleAnalyticsService,
    ConfigService],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {
}
