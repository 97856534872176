<div class="row containerIn">
    <div class="columns stats_one">
        <div class="columns stats_container">
            <div class="medium-3 sequence_match_lable_first">Sequence match</div>
            <div class="columns small-5 medium-2 sequence_match_lable_hsp"><span>HSP score:</span> {{getLabelValue('HSP score', res.sequence_stats)}}</div>

            <div class="columns small-5 medium-2 sequence_match_lable_last"><span>E-value: </span>{{getLabelValue('E-value', res.sequence_stats)}}</div>
        </div>
    </div>
    <div class="columns stats_two">
        <div class="columns stats_container">
            <div class="medium-3 sequence_match_lable_first">&nbsp;</div>
            <div class="columns  small-5  medium-2 sequence_match_lable_hsp"><span>Identity:</span> {{getLabelValue('Identity', res.sequence_stats)}}</div>
            <div class="sequence_match_lable_postives"><span>Positives:</span> {{getLabelValue('Positives', res.sequence_stats)}}</div>
            <div class="columns medium-2 sequence_match_lable_last"><span>Gaps: </span>{{getLabelValue('Gaps', res.sequence_stats)}}</div>
        </div>
    </div>
    <div class="columns sequence_row_second">
        <div class="columns" style="display:inline-block; padding-left:0px;">
            <div *ngIf="!greaterThanLimit(res.query_sequence)" class="columns sequence_container">
                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query">Your query</div>
                    <div class="small-10 sequence_query">
                        <span class="sequence_stat_num">1</span>
                        <p style="white-space:nowrap;">{{ res.query_sequence | slice:0:60 }}</p>
                        <span class="sequence_query_length">{{res.query_sequence.length}}</span>
                    </div>
                </div>

                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query"></div>
                    <div class="small-10 sequence_query">
                        <span class="sequence_stat_num"> &nbsp;</span>
                        <p style="white-space:pre-wrap;">{{ res.match_sequence | slice:0:60 }}</p>
                        <span class="sequence_query_length">&nbsp;</span>
                    </div>
                </div>

                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query">{{res.accession}}</div>
                    <div class="small-10 sequence_query">
                        <span class="sequence_stat_num">1</span>
                        <p style="white-space:nowrap;">{{ res.match_accession | slice:0:60 }}</p>
                        <span class="sequence_query_length">{{res.match_accession.length}}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="greaterThanLimit(res.query_sequence) && canShowSingle" class="columns sequence_container">
                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query">Your query</div>
                    <span class="sequence_stat_num">1</span>
                    <div class="small-10 sequence_query">

                        <p style="white-space:nowrap;">{{ res.query_sequence | slice:0:60 }}</p>
                        <span class="sequence_query_length">60</span>
                    </div>
                </div>

                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query"></div>
                    <span class="sequence_stat_num"></span>
                    <div class="small-10 sequence_query">

                        <p style="white-space:pre-wrap;">{{ res.match_sequence | slice:0:60 }}</p>
                        <span class="sequence_query_length">&nbsp;</span>
                    </div>
                </div>

                <div style="display: flex;">
                    <div class="small-2 sequence_stat_query">{{res.accession}}</div>
                    <span class="sequence_stat_num">1</span>
                    <div class="small-10 sequence_query">

                        <p style="white-space:nowrap;">{{ res.match_accession | slice:0:60 }}</p>
                        <span class="sequence_query_length">60</span>
                    </div>
                </div>
            </div>

            <div *ngIf="greaterThanLimit(res.query_sequence) && canShowAll">
                <div *ngFor="let querystring of getStringsChunks(res.query_sequence)" class="columns sequence_container">
                    <div style="display: flex;">
                        <div class="small-2 sequence_stat_query">Your query</div>
                        <span class="small-1 sequence_stat_num">{{querystring.start}}</span>
                        <div class="small-10 sequence_query">
                            <p style="white-space:nowrap;">{{ querystring.query_chunk }}</p>
                            <span class="sequence_query_length">{{querystring.counttoshow}}</span>
                        </div>
                    </div>

                    <div style="display: flex;">
                        <div class="small-2 sequence_stat_query"></div>
                        <span class="sequence_stat_num"></span>
                        <div class="small-10 sequence_query">
                            <p style="white-space:pre-wrap;">{{ res.match_sequence | slice: querystring.start-1:querystring.counttoshow }}</p>
                            <span class="sequence_query_length">&nbsp;</span>
                        </div>
                    </div>

                    <div style="display: flex;">
                        <div class="small-2 sequence_stat_query">{{res.accession}}</div>
                        <span class="sequence_stat_num">{{querystring.start}}</span>
                        <div class="small-10 sequence_query">
                            <p style="white-space:nowrap;">{{ res.match_accession | slice: querystring.start-1:querystring.counttoshow }}</p>
                            <span class="sequence_query_length">{{querystring.counttoshow}}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="greaterThanLimit(res.query_sequence)" class="flexititle">
                <a *ngIf="isShowingAll" class="showMoreLink" (click)="switchTexttoFull(text)">Show full alignment
                    <svg class="showicon" viewBox="0 0 25 25">
                    <path fill="currentColor"
                        d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"/>
                    </svg>
                </a>
                <a *ngIf="!isShowingAll" class="showMoreLink" (click)="switchTexttoLess(text)">Show less
                    <svg class="showicon" viewBox="0 0 25 25">
                    <path fill="currentColor"
                        d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"/>
                    </svg>
                </a>
            </div>

        </div>
    </div>
</div>
