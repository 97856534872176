<div *ngIf="hasData == -1" class="row" id="loading">
  <div class="small-12 columns" style="text-align: center; margin: 90px 0;">
    <img src="../../assets/img/loader.gif">
  </div>
</div>

<div *ngIf="hasData == 0" class="row" id="not-found">
  <div class="small-11 small-offset-1 columns">
    <h1>Error: 404</h1>
    <h2>We’re sorry - we can’t find the page or file you requested.</h2>
    <p>We’re sorry - we can’t find the page or file you requested.<br>
      It may have been removed, had its name changed, or be temporarily unavailable.<br>
      You might try searching for it or go <a href="/">back to homepage</a>.</p>
  </div>
</div>

<div *ngIf="hasData == 1 && csvAmData" #newsbox class="news-box">
  <app-news-popup (showLinkClick)="newsShowLinkClick($event)"></app-news-popup>
</div>

<div class="row entrySummary" *ngIf="hasData == 1">
  <div class="columns small-12">
    <app-summary-text [accession]="accession" [data]="summaryData" [csvAmData]="csvAmData" [csvAMFileUrl]="csvAMFileUrl" [entryData]="entryData"
                      (click)="gaService.eventEmitter('summary', 'summary', 'click', accession, undefined)"></app-summary-text>
  </div>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns structureTitleBox">
    <div class="filledBox structurestart">
      <span class="title" id="structurestart">Structure viewer</span>
    </div>
  </div>  
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns viewTitleBox">
    <div class="columns modalspace mobileModal-view" *ngIf="csvAmData">
      <mat-button-toggle-group class="tab" name="molstar-coloring" aria-label="Molstar Color">
        <mat-button-toggle value="plddt" class="titleModel" checked (change)="applyMolstarColoring($event.value)">Model Confidence
        </mat-button-toggle>
          
        <mat-button-toggle value="missense" class="titleModel" (change)="applyMolstarColoring($event.value)">AlphaMissense Pathogenicity
        </mat-button-toggle>
      </mat-button-toggle-group>

    </div>
    <div class="columns modalspace mobileModal-view" *ngIf="isModalConf == true">
      <span class="titleModelConf" *ngIf="!csvAmData">Model Confidence 
        <div class="label-tooltip" style="display: inline; margin-left: 8px;">
          <svg style="width:15px;height:15px" viewBox="0 0 24 24"
              (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
            <path fill="#3B6FB6"
                  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
          </svg>
          <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
            pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
            class="licenseLink">lDDT-Cα</a>).
            It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial. Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.
          </span>
        </div>
        <br/>
      </span>

      <div class="modelInfo"> 
        <div class="confidenceWrapperConf">
          <div class="column legendRow" *ngFor="let legend of viewerLegend">
            <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span><span
            class="legendlabel">{{legend.label}}</span>
          </div>
        </div>
        <div class="column legendDesc">
          AlphaFold produces a per-residue model confidence score (pLDDT) between 0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
        </div>
      </div>
    </div>

    <div class="columns modalspace mobileModal-view" *ngIf="isAlphaMis == true">
      <div class="modelInfo"> 
        <div class="confidenceWrapperAM">
          <div class="column legendRow" *ngFor="let alpha of alphamissenseLegends">
            <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span><span
            class="legendlabel">{{alpha.label}}</span>
          </div>
        </div>
        <div class="column legendDesc">
          The displayed colour for each residue is the average AlphaMissense pathogenicity score across all possible amino acid substitutions at that position.
        </div>
      </div>
    </div>

    <div class="columns small-12 medium-9 molstarView">
      <div class="small-12 columns molstar-container"
          (click)="gaService.eventEmitter('molstar-click', 'molstar', 'click', accession, undefined)">
      </div>
    </div>
    <div class="columns modalspace bigModal-view" *ngIf="csvAmData">
      <mat-button-toggle-group class="tab" name="molstar-coloring" aria-label="Molstar Color">
        <mat-button-toggle value="plddt" class="titleModel" checked (change)="applyMolstarColoring($event.value)">Model Confidence

          <div class="label-tooltip" style="display: inline; margin-left: 8px;">
            <svg style="width:15px;height:15px" viewBox="0 0 24 24"
                (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
              <path fill="#1A1C1A"
                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
            </svg>
            <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left; white-space: break-spaces;">
              pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
              class="licenseLink" (click)="stopChangePropagation($event,'plddt')">lDDT-Cα</a>).
              It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial.
              
              <p class="modelconfPara">Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.</p>
            </span>
          </div>
          
        </mat-button-toggle>
          
        <mat-button-toggle value="missense" class="titleModel" (change)="applyMolstarColoring($event.value)">AlphaMissense Pathogenicity

          <div class="label-tooltip" style="display: inline; margin-left: 8px;">
            <svg style="width:15px;height:15px" viewBox="0 0 24 24"
                (mouseover)="gaService.eventEmitter('alphmissense-tooltip', 'tooltip', 'mouseover', accession, '')">
              <path fill="#1A1C1A"
                    d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
            </svg>
            <span class="label-tooltip-text modelconfPara" style="height:fit-content; font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
              The pathogenicity score classifies variants into 3 categories: Likely benign, Uncertain and Likely pathogenic. The cutoffs were chosen such that variants classified as likely pathogenic or likely benign have 90% expected precision estimated from ClinVar for both classes (<a class="licenseLink" href="https://doi.org/10.1126/science.adg7492" target="_blank" (click)="stopChangePropagation($event,'missense')">Cheng J. et al., 2023</a>).     
            </span>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>

    </div>

    <div class="columns modalspace bigModal-view" *ngIf="isModalConf == true && isShowModelLegends">
      <span class="titleModelConf" *ngIf="!csvAmData">Model Confidence 
        <div class="label-tooltip" style="display: inline; margin-left: 8px;">
          <svg style="width:15px;height:15px" viewBox="0 0 24 24"
              (mouseover)="gaService.eventEmitter('molstar-tooltip', 'tooltip', 'mouseover', accession, undefined)">
            <path fill="#3B6FB6"
                  d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"/>
          </svg>
          <span class="label-tooltip-text modelconfPara" style="font-size: 14px; font-weight: 400; text-align: left;  white-space: break-spaces;">
            pLDDT corresponds to the model’s prediction of its score on the local Distance Difference Test (<a
            href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3799472/" target="_blank"
            class="licenseLink">lDDT-Cα</a>).
            It is a measure of local accuracy - for interpreting larger scale features like relative domain positions see the “predicted aligned error” plot and corresponding tutorial. Confidence bands are used to colour-code the residues in the 3D viewer. The exact pLDDT value is shown when you mouseover the structure or the sequence. It can also be found in the B-factor fields of the downloadable coordinate files.
          </span>
        </div>
       
      </span>
  
      <div class="modelInfo"> 
        <div class="confidenceWrapper">
          <div class="column legendRow" *ngFor="let legend of viewerLegend">
            <span class="legendColor" [ngStyle]="legend.style">&nbsp;</span><span
            class="legendlabel">{{legend.label}}</span>
          </div>
        </div>
        <div class="column legendDesc">
          AlphaFold produces a per-residue model confidence score (pLDDT) between 0 and 100. Some regions below 50 pLDDT may be unstructured in isolation.
        </div>
      </div>
       
    </div>

    <div class="columns modalspace bigModal-view" *ngIf="isAlphaMis == true  && isShowModelLegends && csvAmData">
      <div class="modelInfo"> 
        <div class="confidenceWrapperAM">
          <div class="column legendRow" *ngFor="let alpha of alphamissenseLegends">
            <span class="legendColor" [ngStyle]="alpha.style">&nbsp;</span><span
            class="legendlabel">{{alpha.label}}</span>
          </div>
        </div>
        <div class="column legendDesc">
          The displayed colour for each residue is the average AlphaMissense pathogenicity score across all possible amino acid substitutions at that position.
        </div>
      </div>
    </div>

    <div class="columns modalspace bigModal-view">
      <h3 *ngIf="isShowModelLegendsText && csvAmData" (click)="toggleShowModel()" class="showLegengds">Show colour legend
        <i *ngIf="isShowModelLegendsText && csvAmData" class="icon icon-common" data-icon="&#xf078;"></i>
      </h3>
      <h3 *ngIf="!isShowModelLegendsText && csvAmData" (click)="toggleShowModel()" class="showLegengds">Hide colour legend
        <i *ngIf="!isShowModelLegendsText && csvAmData" class="icon icon-common" data-icon="&#xf077;"></i>
      </h3>
    </div>
   
  </div>
  <div class="columns viewTitleBox2pae">
   
    <app-pae-view *ngIf="isSummaryLoaded && entryData.paeImageUrl" [accession]="accession"
                  [imageUrl]="entryData.paeImageUrl" [length]="entryData.uniprotEnd"
                  (selectedRegion)="cmSelectionUpdate($event)"
                  (click)="gaService.eventEmitter('pae-click', 'pae', 'click', accession, undefined)">
    </app-pae-view>
   
    <div class="columns paeDescription modalspace">
      <p class="title-pae">Predicted aligned error (PAE)</p>
      <p class="desc">Click and drag a box on the PAE viewer to select regions of the structure and highlight them on the 3D viewer. </p>
      <p class="desc">PAE data is useful for assessing inter-domain accuracy – 
        <a [routerLink]="['./']" fragment="help" target="_self">go to Help section below</a> for more information.</p>
    </div>
  </div>
</div>
<div id="heatmapends"></div>
<app-heatmap [accession]="accession" [amAnnotationsHg19Url]="amAnnotationsHg19Url" [amAnnotationsHg38Url]="amAnnotationsHg38Url" [csvAMFileUrl]="csvAMFileUrl" [heatmapSequence]="heatmapSequence" [csvAmData]="csvAmData" (selectedCategory)="amSelectionCategory($event)" (selectedRegion)="amSelectionUpdate($event)" (clearRegion)="amSelectionClear()" *ngIf="csvAmData"></app-heatmap>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns structureTitleBox">
    <div class="filledBox ">
      <span class="title">Structure similarity cluster</span>
      <span class="paeDescription">Predicted structures in the AlphaFold Protein Structure Database clustered using <a (click)= "gaService.eventEmitter(
        'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://github.com/soedinglab/mmseqs2" target="_blank">MMseq2</a> and 
        <a (click)= "gaService.eventEmitter(
          'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://github.com/steineggerlab/foldseek" target="_blank">Foldseek</a>.
         This data is provided by the 
        <a (click)= "gaService.eventEmitter(
          'clusters', 'structure_similarity', 'click', 'cluster_urls', 'Clicks on the MMseqs2, Foldseek, AFDB Clusters and Stenegger et al URLs in the cluster members table')" href="https://cluster.foldseek.com/" target="_blank">AFDB Clusters</a>. </span>
    </div>
  </div>  
</div>

<div class="row threedViewer structureContainer" *ngIf="hasData == 1">
  <app-cluster-table [accession]="accession"></app-cluster-table>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <hr class="faq-border"/>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="columns helpTitle licenseHeading" id="help">Help

    <div class="columns">
      <br>
        <span class="normal-text">
          <a class="search-help-link vf-section-header__heading--is-link" href="https://www.ebi.ac.uk/training/online/courses/alphafold/ " target="_blank"
           (click)="gaService.eventEmitter('link_visit', 'training_href_click', 'click', 'training_entry_page_link', 'Clicks on the training link on every entry page')">
          <span style="display: inline-block; vertical-align: middle;">Visit our online training course</span>
          <span style="display: inline-block; vertical-align: middle; padding-top: 2px">
            <svg aria-hidden="true" class="vf-section-header__icon | vf-icon vf-icon-arrow--inline-end" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12S18.627 0 12 0C5.376.008.008 5.376 0 12zm13.707-5.209l4.5 4.5a1 1 0 010 1.414l-4.5 4.5a1 1 0 01-1.414-1.414l2.366-2.367a.25.25 0 00-.177-.424H6a1 1 0 010-2h8.482a.25.25 0 00.177-.427l-2.366-2.368a1 1 0 011.414-1.414z" fill="#3B6FB6" fill-rule="nonzero"></path>
            </svg>
          </span>
        </a>
        </span>
  
      <app-faqs-list [paeExplaination]="true"
      (click)="gaService.eventEmitter('pae-help-click', 'pae', 'click', accession, undefined)"></app-faqs-list>
    </div>
  </div>
</div>

<div class="row threedViewer" *ngIf="hasData == 1">
  <div class="small-12">
    <h3 class="licenseHeading">Last updated</h3>
<!--    <p class="licenseText">Last updated in AlphaFold DB version {{lastUpdateDate}}, created with the AlphaFold Monomer v2.0 pipeline.</p>-->
    <p class="licenseText">Last updated in AlphaFold DB version 2022-11-01, created with the AlphaFold Monomer v2.0 pipeline.</p>
  </div>
</div>
<div class="row" *ngIf="hasData == 1">
  <div class="small-12">
    <h3 class="licenseHeading">Licence and attribution</h3>
    <p class="licenseText">
      Data is available for academic and commercial use, under a <a class="licenseLink"
                                                                    href="https://creativecommons.org/licenses/by/4.0/"
                                                                    target="_blank">CC-BY-4.0</a> licence.
      <br><br>
      EMBL-EBI expects attribution (e.g. in publications, services or products) for any of its online services, databases or software in accordance with good scientific practice.
      <br><br>
      If you make use of an AlphaFold prediction, please cite the following papers:<br class="hide-for-small-only">
      <a class="licenseLink" (click)="gaService.eventEmitter(
      'link_visit',
      'home_href_click',
      'click',
      'methods_paper',
      '')" href="https://www.nature.com/articles/s41586-021-03819-2" target="_blank">
        Jumper, J <i>et al</i>. Highly accurate protein structure prediction with AlphaFold. <i>Nature</i> (2021).</a><br>
        <a class="licenseLink" (click)="gaService.eventEmitter(
          'link_visit',
          'home_href_click',
          'click',
          'nar_paper',
          '')" href="https://academic.oup.com/nar/advance-article/doi/10.1093/nar/gkad1011/7337620"
             target="_blank" style="display:block; margin-top: 10px;">
            Varadi, M <i>et al</i>. 
            AlphaFold Protein Structure Database in 2024: providing structure coverage for over 214 million protein sequences. <i>Nucleic Acids Research</i> (2024).
            </a>
            <br>
            If you use data from AlphaMissense in your work, please cite the following paper:<br class="hide-for-small-only">
            <a class="licenseLink" (click)="gaService.eventEmitter(
            'link_visit',
            'home_href_click',
            'click',
            'methods_paper',
            '')" href="https://www.science.org/doi/10.1126/science.adg7492" target="_blank">
            Cheng, J et al. Accurate proteome-wide missense variant effect prediction with AlphaMissense. Science (2023).</a>
                 
      <br><br>
      AlphaFold Data Copyright (2022) DeepMind Technologies Limited.<br>
      AlphaMissense Copyright (2023) DeepMind Technologies Limited.
    </p>
  </div>
</div>

<div class="row " *ngIf="hasData == 1">
  <div class=" small-12">
    <h3 class="licenseHeading">Feedback and questions</h3>
    <p class="licenseText">
      If you want to share feedback on an AlphaFold structure prediction, consider using the feedback buttons at the top of each structure page.
      If you have any questions that are not covered in the <a class="licenseLink" href="/faq" target="_blank">FAQs</a>, please contact
      <a class="licenseLink" href="mailto:alphafold@deepmind.com?subject={{accession}}">alphafold@deepmind.com</a>.
      If you have feedback on the website or experience any bugs please
      contact <a class="licenseLink" href="mailto:afdbhelp@ebi.ac.uk?subject={{accession}}">afdbhelp@ebi.ac.uk</a>.
    </p>
    <p class="licenseText">Let us know how the AlphaFold Protein Structure Database has been useful in your research at
      <a class="licenseLink"
         href="mailto:alphafold@deepmind.com?subject={{accession}}">alphafold@deepmind.com</a>.</p>
  </div>
</div>

<div class="row updatedtextLast" *ngIf="hasData == 1">
  <div class=" small-12">
    <h3 class="licenseHeading">Disclaimer</h3>
    <p class="licenseText">
      The AlphaFold and AlphaMissense Data and other information provided on this site contain predictions with varying levels of confidence, is for theoretical modelling only and caution should be exercised in its use. It is provided 'as-is' without any warranty of any kind, whether expressed or implied. For clarity, no warranty is given that use of the information shall not infringe the rights of any third party. The information is not intended to be a substitute for professional medical advice, diagnosis, or treatment, and does not constitute medical or other professional advice. The AlphaFold and AlphaMissense Data have not been validated for, and are not approved for, any clinical use. 
      <br><br>Use of the AlphaFold Protein Structure Database is subject to EMBL-EBI <a
      href="https://www.ebi.ac.uk/about/terms-of-use/" target="_blank" class="licenseLink">Terms of Use</a>.
    </p>
  </div>
</div>