<div class="header for-mobile bounce-from-right" *ngIf="seenInfo === 'false' || seenInfo === null">
  <div class="frame-parent">
    <div class="badges-parent">
      <div class="badges">
        <div class="new">New!</div>
      </div>
      <div class="alphamissense-pathogenicity-da">
        AlphaMissense Pathogenicity data
      </div>
      <img (click)="dismissModal()" class="iconclose" alt="" src="assets/img/crossicon.svg" />
    </div>
    <div class="badges-group">
      <div class="badges1">
        <div class="new">New!</div>
      </div>
      <div class="explore-pathogenicity-data">
        Explore pathogenicity data in heatmap and 3D viewer.
      </div>
    </div>
  </div>
  <div class="header-inner">
    <div class="scroll-down-to-explore-parent">
      <div class="scroll-down-to-explore">
        <div class="scroll-down-to"><a (click)="showClick($event)">Scroll down to explore</a></div>
      </div>
      <img class="mdiarrow-down-icon" alt="" src="mdi:arrow-down.svg" />
    </div>
  </div>
</div>
